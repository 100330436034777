@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import "~antd/dist/antd.less";
@import "./flex.less";
@import "./spacing.less";
@import "./media-queries.less";

@accent-color: #20c4f4;
@dark-blue: #153051;
@muted-text: @text-color-secondary;
@dark: @text-color-secondary-dark;
@gray: rgb(162, 171, 183);
@feed-padding: 24px;
@light-bg: @body-background;
@border-light: 1px solid @body-background;
@dark-light-bg: #484f66;
@transition: 0.25s ease;
@shadow: 0px 3px 6px rgba(9, 50, 84, 0.02);
@feed-distanc: 80px;
@card-radius: 8px;
@card-padding: 15px;

html {
  scroll-behavior: smooth;
}

.app-wrapper {
  min-height: 100vh;
}

.text-left {
  text-align: left !important;
}

// TODO: generator for responsive text alignment
.text-lg-right {
  .Mq-min(@screen-lg, {
    text-align: right;
  });
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-bold {
  font-weight: bold;
}
.text-small {
  font-size: 12px;
}
.text-primary {
  color: @primary-color;
}
.no-bg,
.background-none {
  background: none !important;
}
.w-100 {
  width: 100%;
}
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.muted {
  color: @muted-text;
}
a.muted:hover {
  color: @accent-color;
}
.bg-white {
  background-color: #fff;
}
.bg-muted {
  background-color: @light-bg;
}

.min-height-100 {
  min-height: 200px;
}
.min-height-200 {
  min-height: 200px;
}
.min-height-300 {
  min-height: 300px;
}

h1,
h2,
h3 {
  font-weight: 600;
  small {
    font-weight: normal;
    display: block;
  }
}

h2 {
  .Mq(@screen-sm, {
    font-size: 1.3em;
  });
}

// main content wrapper
main.content {
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 24px 24px 0 24px;
  margin: 0 auto;
  .Mq(@screen-md; {padding:0;});
}

.content-bg {
  box-shadow: @shadow;
  background-color: #fff;
  border-radius: 8px;
  .Mq(@screen-md; {border-radius: 0;});
}

.logo-wp {
  margin: 20px auto;
  width: 200px;
  height: 76px;
  transition: width 250ms ease;
  svg {
    transition: width 250ms ease, height 250ms ease;
    width: 160px;
    height: 70px;
  }
}

small {
  color: @muted-text;
  font-size: 12px;
}
.d-flex {
  .flex-box;
}

// Card
.card-heading {
  min-height: 70px;
  .align-items(center);
  padding: 0 @card-padding;
  border-bottom: 2px solid @light-bg;
  .ant-select-selector {
    min-width: 100px;
  }
  .card-exstra-title {
    padding: 0 @card-padding;
  }
}

.card-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

.user-card-title {
  .flex-box();
  .align-items(center);
  h3 {
    display: inline-block;
    margin: 0 0 0 10px;
    small {
      display: block;
      color: @muted-text;
    }
  }
}

.card-footer {
  padding: @card-padding;
}

.flex-wrap-lg {
  .Mq-min(@screen-lg; {
    .flex-wrap();
  });
}
.flex-wrap {
  .flex-wrap();
}

.card-body {
  min-height: 350px;
  .Mq-min(@screen-lg; {
    .flex-wrap();
  });
  > .ant-col {
    padding: @card-padding;
  }

  .card-sider {
    .flex-box();
    .flex-direction(column);
    .justify-content(space-between);
    padding: @card-padding 0 0 0;
    border-left: 2px solid @light-bg;
    .card-affix {
      .ant-anchor-wrapper {
        margin-left: -6px;
      }
    }
    .card-actions {
      text-align: right;
      padding: @card-padding;
    }
  }
}

.offer-calculation {
  font-size: 16px;
  margin: 10px 0;
  position: relative;
  padding: 26px 5px 20px 5px;
  background: @light-bg;
  &::before,
  &::after {
    background: linear-gradient(-45deg, #ffffff 8px, transparent 0),
      linear-gradient(45deg, #ffffff 8px, transparent 0);
    background-position: -8px bottom;
    background-repeat: repeat-x;
    background-size: 16px 16px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 16px;
  }
  &::before {
    transform: rotate(180deg);
    bottom: auto;
    top: 0px;
  }
  .info-group-wp {
    border-radius: 6px;
    padding: 12px 12px 8px 12px;
  }
}

// Forms & Anchors

.ant-switch-null .ant-switch-handle {
  left: calc(100% - 31px);
  &::before {
    color: @muted-text;
  }
}
.ant-switch-handle::before {
  content: "|";
  transition: all @transition;
  color: transparent;
  line-height: 18px;
  font-size: 9px;
}

.input-number-wp {
  position: relative;
  .input-number-extra {
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 37px;
    z-index: 0;
    cursor: auto;
  }
}

.tail-form-btns {
  padding-left: 80px;
}

.tail-layout-field {
  .ant-form-item-explain {
    margin-bottom: 8px;
  }
}
.ant-form-item-label {
  .Mq(@screen-lg, {
    text-align: left;
    > label {
      height: 22px;
      padding-bottom: 8px;
    }
  });
  text-align: right;
}

.btn-checkbox {
  > .ant-checkbox {
    display: none;
  }
  span:last-child {
    color: @text-color;
    margin-left: 1em;
    padding: 0 1em;
    height: 37px;
    text-align: center;
    line-height: 37px;
    border-radius: 6px;
    border: 1px solid rgba(@gray, 0.4);
    display: inline-block;
    transition: color @transition, border-color @transition,
      background-color @transition;
    &:hover {
      border-color: @blue-base;
      color: @blue-base;
    }
  }
  &.ant-checkbox-wrapper-checked {
    span:last-child {
      border-color: @blue-base;
      background-color: @blue-base;
      color: #fff;
      &:hover {
        color: @blue-base;
        border-color: @blue-base;
        background-color: #fff;
      }
    }
  }
}

.input-group-btn {
  .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-btn {
    border-radius: 0;
    padding-left: 10px;
    padding-right: 10px;
    &:last-child {
      border-top-right-radius: @btn-border-radius-base;
      border-bottom-right-radius: @btn-border-radius-base;
    }
  }
}

.ant-radio-group.ant-radio-group-solid {
  background: @light-bg;
  border-radius: 6px;
  .ant-radio-button-wrapper {
    border-radius: 6px;
    border: 0;
    &::before {
      content: none;
    }
  }
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background: transparent;
  }
}

.no-steps-btns {
  width: 100% !important;
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

.ant-upload {
  width: 100%;
  p {
    margin-bottom: 8px;
  }
  .anticon {
    font-size: 22px;
    margin-right: 8px;
    vertical-align: -0.3em;
  }
  small {
    display: block;
  }
}
.ant-form-item-has-warning {
  .ant-form-item-label label {
    color: @orange-base;
  }
  .ant-upload-drag {
    border-color: @orange-base;
  }
}
.ant-upload-list > div {
  display: inline-block;
  width: 100%;
}

.ant-upload-list-item-card-actions .anticon-loading .anticon {
  top: 13px;
  left: 10px;
  font-size: 18px;
}
.ant-upload-list-item {
  height: 44px;
}
.ant-upload-list-item-name {
  color: @text-color;
}

.ant-upload-list-item-info {
  border-radius: 6px;
  padding: 10px;
  height: 44px;
  background-color: #f8f9fb;
  & > span {
    display: flex;
  }
  .ant-upload-text-icon {
    .ant-switch .anticon {
      color: @text-color;
    }
    .ant-switch-checked .anticon {
      color: #fff;
    }
    .anticon {
      position: relative;
      top: auto;
    }
  }
}

.ant-upload-list-item-card-actions {
  right: 10px;
  .ant-upload-list-item-card-actions-btn {
    background-color: transparent;
    &:hover {
      .anticon.trash-outline-icon {
        color: @red-base;
      }
    }
    .anticon {
      font-size: 18px;
    }
  }
}

.noresize-text-area {
  resize: none;
}
.ant-checkbox-inner {
  border-radius: 5px;
}
.ant-checkbox-inner::after {
  top: 45%;
  left: 23%;
}

.ant-picker {
  width: 100%;
}

@blue-base: #0299FB;@green-base: #1EC670;@red-base: #FF2424;@orange-base: #FF9524;@primary-color: #0299FB;@background-color-light: #F8F9FB;@heading-color: #153051;@text-color: #5A6870;@text-color-secondary: #9CA4A9;@text-color-dark: #C0C4D1;@text-color-secondary-dark: #687393;@body-background: #F2F3F8;@menu-bg: transparent;@menu-dark-color: @text-color-dark;@menu-dark-bg: transparent;@menu-item-active-bg: #181D2D;@menu-item-height: 45px;@menu-dark-item-active-bg: #181D2D;@menu-item-font-size: 15px;@menu-icon-margin-right: 10px;@menu-icon-size: 19px;@menu-icon-size-lg: 19px;@layout-sider-background: #242939;@layout-trigger-background: #242939;@layout-header-height: 80px;@layout-header-padding: 0 20px;@layout-header-background: #FFFFFF;@layout-footer-padding: 24px;@table-border-radius-base: 0;@table-header-bg: #F8F9FB;@table-header-color: @text-color;@font-family: Poppins;@border-radius-base: 6px;@border-radius-sm: 4px;@height-base: 38px;@item-hover-bg: #F8F9FB;@link-hover-color: #20C4F4;@input-placeholder-color: rgb(162, 171, 183);@btn-shadow: 0px 2px 4px rgba(9, 50, 84, 0.1);@btn-primary-shadow: 0px 2px 4px rgba(9, 50, 84, 0.1);@btn-padding-horizontal-base: 24px;@btn-disabled-color: #b1b9be;@page-header-heading-title: @heading-2-size;@page-header-back-color: #242939;@page-header-padding: 0px;@page-header-padding-vertical: 16px;@pagination-item-bg: #F2F3F8;@pagination-item-input-bg: #F2F3F8;@pagination-item-disabled-bg-active: #F2F3F8;@pagination-item-disabled-color-active: #C0C4D1;@pagination-item-link-bg: #F2F3F8;@normal-color: #F2F3F8;@border-color-base: #F2F3F8;@border-color-split: #F2F3F8;@avatar-size-base: 40px;@select-background: #F2F3F8;@checkbox-check-color: #f0f2f5;@btn-text-hover-bg: #F2F3F8;@picker-bg: #F2F3F8;@input-bg: #F2F3F8;