.Mq(@maxWidth; @rules;) {
  @media only screen and (max-width: (@maxWidth - 1px)) {
    @rules();
  }
}

.Mq-min(@minWidth; @rules;) {
  @media only screen and (min-width: @minWidth) {
    @rules();
  }
}

.Mq-between(@minWidth; @maxWidth; @rules;) {
  @media only screen and (max-width: (@maxWidth - 1px)) and (min-width: @minWidth) {
    @rules();
  }
}
