.spacing() {
  0: 0;
  05: 0.5em;
  1: 1em;
  2: 1.5em;
  3: 2em;
  4: 2.5em;
  5: 3em;
  6: 5em;
}

.props() {
  m: margin;
  p: padding;
}

.sides() {
  t: top;
  b: bottom;
  l: left;
  r: right;
}

each(.props(), #(@propVal, @propKey){
    each(.spacing(), #(@selecVal, @selecKey) {
      .@{propKey}-@{selecKey} {
        @{propVal}: @selecVal;
    }
        each(.sides(), #(@sideVal,@sidekey) {
            .@{propKey}@{sidekey}-@{selecKey} {
                @{propVal}-@{sideVal}: @selecVal;
            }
        });
    });
});

.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
